import React, { useState } from "react";
import { useRefs } from '../../Pages/RefsContext'


const Faqs = () => {
    const { faqRef } = useRefs();

  return (
    <>
            <section ref={faqRef} className="services-area vx_ecosystem-main section-pt-50 section-pb-50">

      <div className="container my-5">
        <div className="text-center mb-5">
        <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className="vx_section-title text-center mb-60"
              >
                <span className="vx_sub-title vx_animate-text">
                  <span>Questions</span>
                </span>
              </div>
          <h1 className="text-capitalize   font-weight-bold">
            Frequently Asked <span >Questions</span>
          </h1>
        </div>

    
         <div class="faq__body">
       <details aria-expanded="false" class="faq__panel" >
           <summary class="faq__label"> What is VitnixX Coin?</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">VitnixX Coin (VTC) is a blockchain-based cryptocurrency designed for our decentralized gaming platform and NFT marketplace. It serves as the primary utility coin within the Vitnixx ecosystem, enabling governance, rewards, and in-game transactions.</p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label">What sets VitnixX apart from traditional online gaming systems?</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">VitnixX distinguishes itself through decentralization, transparency, and community-driven governance. We utilize blockchain technology to ensure fairness and trust in gaming and provide users with control over their assets.</p>
           </div>
       </details>
    
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> How do I set up my wallet on Polygon chain?</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">Trust Wallet: When you go into the dApps in the top-right corner you can see the different blockchains available, click on it then choose Polygon Chain Mainnet.
           MetaMask: Create a custom RPC with the following Polygon Mainnet in the Network Name field, URL https://polygon-rpc.com/ in the New RPC URL field, 137 in Chain ID field, MATIC in Currency Symbol field and https://polygonscan.com/ in Block Explorer URL field.

           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> Where can I purchase VitnixX NFTs?</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">VitnixX NFTs are available for purchase on multiple platforms. You can acquire them on:<br/>
           Opensea: A popular and widely recognized NFT marketplace where you can explore and buy a diverse range of digital collectibles.<br/>
           VitnixX Marketplace: We have our own NFT marketplace, designed specifically for the VitnixX community. Here, you can discover, mint, and trade unique NFTs created by artists and collectors within our ecosystem.

           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> What makes VitnixX different from traditional gaming platforms?</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">
VitnixX distinguishes itself through decentralization, transparency, and a community-centric approach. We provide fairness, user control, and opportunities for creators and collectors. 
           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> What games are available on VitnixX?</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">
           VitnixX offers a diverse range of online games, from classics to innovative new games. Explore our gaming section to discover the available titles.
           </p>
           </div>
       </details>
   </div>
      </div>
      </section>

     
    </>
  );
};

export default Faqs;
