import React from 'react'
// import banneranim2 from '../../Assets/images/banneranim-2.webp'
import nftaboutsection from '../../Assets/images/Revolutionizing Gaming AND NFTs.png'

import { useRefs } from '../../Pages/RefsContext'
const Games = () => {
    const { gameRef } = useRefs();
    return (
        <>
            {/* Games Section Start */}
            <section ref={gameRef} className="services-area vx_ecosystem-main section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                    <span className="vx_sub-title vx_animate-text"><span>Games</span></span>
                                </div>
                                <h2 className='title mb-3'>Revolutionize Your Gaming Experience</h2>
                                <p>Experience gaming like never before with VitnixX. Our decentralized gaming platform offers a wide range of fair and exciting games, all powered by blockchain technology. From classics to innovative new games, we've got something for every gamer. Join us in reshaping the future of online gaming.</p>
                                <a href="https://game.vitnixx.com/" className="vx_border-button mt-3" target='_blank'><span>View Games</span></a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-0 text-center">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_services-images">
                            <img src={nftaboutsection}  width={500} height={300} alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Games Section End */}
        </>
    )
}

export default Games