import React from 'react'
import Banner from '../../Components/Banner/Banner'
import Ecosystem from '../../Components/Ecosystem/Ecosystem'
import Faqs from '../../Components/Faqs/Faqs'
import Features from '../../Components/Features/Features'
import Polygon from '../../Components/Polygon/Polygon'
import Roadmap from '../../Components/Roadmap/Roadmap'
import Statistical from '../../Components/Statistical/Statistical'
import Tokenomics from '../../Components/Tokenomics/Tokenomics'
import Aboutus from '../../Components/Aboutus/Aboutus'
import Games from '../../Components/Games/Games'
import Nftsec from '../../Components/Nftsec/Nftsec'
import Animation from '../../Components/Animation/Animation'

const Home = () => {
    return (
        <>
            <Banner />
            <Aboutus />
            <Polygon />
            <Games />
            <Nftsec />
            <Features />
            <Animation />
            <Tokenomics />
            <Statistical />
            {/* <Ecosystem /> */}
            <Roadmap />
            <Faqs />
        </>
    )
}

export default Home