import React from 'react'
import polygon from '../../Assets/images/polygon.webp'

const Polygon = () => {
    return (
        <>
            {/* Polygon Section Start */}
            <section className="vx_polygonmain section-pt-50 section-pb-50">
                <div className="container">
                    <div className="create__main">
                        <div className="content row justify-content-center md:justify-content-between">
                            <div className="col-xl-9 col-lg-9 vx_section-title">
                                <span className="vx_sub-title vx_animate-text"><span>Polygon</span></span>
                                <h3 className="title">Integrated Blockchain</h3>
                                <img src={polygon} width="180" height="180" alt="polygon img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Polygon Section End */}
        </>
    )
}

export default Polygon