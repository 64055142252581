import React from 'react'
import { useRefs } from "../../Pages/RefsContext";


const Roadmap = () => {
    const { roadmapRef } = useRefs();


    return (
        <>
            <section  ref={roadmapRef}  className="services-area vx_ecosystem-main section-pt-50 section-pb-50">
        <div className="container">

        <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className="vx_section-title text-center mb-60"
              >
                <span className="vx_sub-title vx_animate-text">
                  <span>Roadmap</span>
                </span>
                <h3 className="title">Roadmap</h3>
              </div>
<div class="timeline">

	<div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
		<div class="timeline__event__icon ">

		</div>
		<div class="timeline__event__date">
		(Q2 2023) 
		</div>
		<div class="timeline__event__content ">
			<div class="timeline__event__title">
			Phase 1: Inception and Planning 
			</div>
			<div class="timeline__event__description">
			</div>
		</div>
	</div>


	<div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
		<div class="timeline__event__icon">

		</div>
		<div class="timeline__event__date">
		(Q3 2023)
		</div>
		<div class="timeline__event__content">
			<div class="timeline__event__title">
            Phase 2: Platform Development
			</div>
			<div class="timeline__event__description">
			</div>
		</div>
	</div>


	<div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
		<div class="timeline__event__icon">
		</div>
		<div class="timeline__event__date">
        (Q4 2023)
		</div>
		<div class="timeline__event__content">
			<div class="timeline__event__title">
            Phase 3: Swapping , NFT Marketplace, Game Launch
			</div>
			<div class="timeline__event__description">
			</div>

		</div>
	</div>


	<div class="timeline__event animated fadeInUp timeline__event--type1">
		<div class="timeline__event__icon">

		</div>
		<div class="timeline__event__date">
		(Q1 2024)
		</div>
		<div class="timeline__event__content">
			<div class="timeline__event__title">
				
Phase 4: Expansion and Diversification
			</div>
			<div class="timeline__event__description">
			</div>
		</div>
	</div>

	<div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
		<div class="timeline__event__icon ">

		</div>
		<div class="timeline__event__date">
		(Q2 2024)
		</div>
		<div class="timeline__event__content ">
			<div class="timeline__event__title">
            Phase 5: Global Outreach and Education
			</div>
			<div class="timeline__event__description">
			</div>
		</div>
	</div>


	<div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
		<div class="timeline__event__icon">

		</div>
		<div class="timeline__event__date">
		(Q3 2024)
		</div>
		<div class="timeline__event__content">
			<div class="timeline__event__title">
			Phase 6: Technological Advancements and Ecosystem Building
			</div>
			<div class="timeline__event__description">
			</div>
		</div>
	</div>


	<div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
		<div class="timeline__event__icon">

		</div>
		<div class="timeline__event__date">
        (Q4 2024)
		</div>
		<div class="timeline__event__content">
			<div class="timeline__event__title">
            Phase 7: Celebration and Future Planning 
			</div>
			<div class="timeline__event__description">
			</div>

		</div>
	</div>

	
        </div>
</div>
</section>
        </>
    )
}

export default Roadmap