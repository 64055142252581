import React from 'react'

const Statistical = () => {
    return (
        <>
            {/* Statistical Section Start */}
            <section className="section-pt-50 section-pb-50">
                <div className="container">
                    <div data-aos="fade-up" data-aos-duration="1500" className="row justify-content-center mb-5">
                        <div className="col-xl-6 col-lg-7 col-md-10">
                            <div className="vx_section-title text-center mb-60">
                                <span className="vx_sub-title vx_animate-text"><span>Statistical</span></span>
                                <h3 className="title">Statistical Data</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center flex-wrap">
                        <div className="col-xl-4 col-md-6">
                            <div className="speciality-box">
                                <div className="icon">
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4995 11.1667H14.666V10.0001C14.666 4.84542 18.8447 0.666748 23.9994 0.666748H37.9994C43.1539 0.666748 47.3327 4.84542 47.3327 10.0001V24.0001C47.3327 29.1546 43.1539 33.3334 37.9994 33.3334H36.8327V22.5C36.8327 16.2409 31.7586 11.1667 25.4995 11.1667ZM9.99935 47.3334H23.9994C29.1539 47.3334 33.3327 43.1547 33.3327 38.0001V24.0001C33.3327 18.8454 29.1539 14.6667 23.9994 14.6667H9.99935C4.84469 14.6667 0.666016 18.8454 0.666016 24.0001V38.0001C0.666016 43.1547 4.84469 47.3334 9.99935 47.3334ZM25.1518 25.0165C25.8791 25.6528 25.9528 26.7586 25.3163 27.4859L18.6199 35.139C17.181 36.7835 14.7024 37.0035 12.996 35.6385L8.90614 32.3667C8.15142 31.7628 8.02906 30.6615 8.63283 29.9069C9.23658 29.1521 10.3378 29.0298 11.0926 29.6337L15.1825 32.9055C15.4262 33.1006 15.7803 33.069 15.9859 32.8341L22.6824 25.181C23.3187 24.4537 24.4243 24.3799 25.1518 25.0165Z" fill="url(#paint0_linear_939_616)"></path>
                                        <defs>
                                            <linearGradient id="paint0_linear_939_616" x1="23.9993" y1="0.666748" x2="23.9993" y2="47.3334" gradientUnits="userSpaceOnUse">
                                                <stop offset="1" stop-color="white"></stop>
                                                <stop offset="1" stop-color="white" stop-opacity="0.5"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h2 className='mb-3'>Total Supply</h2>
                                <h3 className="number">200 Million</h3>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="speciality-box">
                                <div className="icon">
                                <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.2612 1.68623L46.0794 9.45348C47.6617 11.256 47.7559 13.9428 46.3037 15.8544L27.9319 40.0393C25.9458 42.6536 22.0529 42.6536 20.0667 40.0393L1.6949 15.8544C0.242769 13.9428 0.33692 11.256 1.91922 9.45348L8.73743 1.68623C9.67896 0.613667 11.0285 0 12.4457 0H19.5891H28.9607H35.5531C36.9701 0 38.3197 0.613667 39.2612 1.68623Z" fill="url(#paint0_linear_939_5927)"></path><defs><linearGradient id="paint0_linear_939_5927" x1="23.9993" y1="0" x2="23.9993" y2="42" gradientUnits="userSpaceOnUse"><stop offset="1" stop-color="white"></stop><stop offset="1" stop-color="white" stop-opacity="0.5"></stop></linearGradient></defs></svg>
                                </div>
                                <h2 className='mb-3'>Market Cap</h2>
                                <h3 className="number">100 Million</h3>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="speciality-box">
                                <div className="icon">
                                    <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.99935 4.66667C9.99935 2.08934 12.0887 0 14.666 0H33.3327C35.9101 0 37.9994 2.08934 37.9994 4.66667V7H9.99935V4.66667ZM5.33268 15.1667C5.33268 12.5893 7.42202 10.5 9.99935 10.5H37.9994C40.5768 10.5 42.666 12.5893 42.666 15.1667V17.5H5.33268V15.1667ZM0.666016 25.6667C0.666016 23.0893 2.75535 21 5.33268 21H42.666C45.2434 21 47.3327 23.0893 47.3327 25.6667V32.6667C47.3327 37.8212 43.1539 42 37.9994 42H9.99935C4.84469 42 0.666016 37.8212 0.666016 32.6667V25.6667ZM17.5827 28C17.5827 27.0335 18.3662 26.25 19.3327 26.25H28.666C29.6325 26.25 30.416 27.0335 30.416 28C30.416 28.9665 29.6325 29.75 28.666 29.75H19.3327C18.3662 29.75 17.5827 28.9665 17.5827 28Z" fill="url(#paint0_linear_939_156)"></path><defs><linearGradient id="paint0_linear_939_156" x1="23.9993" y1="0" x2="23.9993" y2="42" gradientUnits="userSpaceOnUse"><stop offset="1" stop-color="white"></stop><stop offset="1" stop-color="white" stop-opacity="0.5"></stop></linearGradient></defs></svg>
                                </div>
                                <h2 className='mb-3'>Current price</h2>
                                <h3 className="number">$0.50</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Statistical Section End */}
        </>
    )
}

export default Statistical