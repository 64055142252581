import React from 'react'
// import banneranim2 from '../../Assets/images/banneranim-2.webp'
import aboutblog1 from '../../Assets/images/about-blog1.webp'
import aboutblog2 from '../../Assets/images/about-blog2.webp'
import aboutblog3 from '../../Assets/images/about-blog3.webp'
import aboutblog4 from '../../Assets/images/about-blog4.webp'
import gamesimg from '../../Assets/images/comp2.gif'
import { useRefs } from "../../Pages/RefsContext";

const Aboutus = () => {
    const { aboutRef } = useRefs();

    return (
        <>
            {/* Aboutus Section Start */}
            <section ref={aboutRef} className="services-area vx_ecosystem-main section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 order-lg-1">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                    <span className="vx_sub-title vx_animate-text"><span>About Us</span></span>
                                </div>
                                <h2 className='title mb-3'>Revolutionizing Gaming & NFTs</h2>
                                <p>VitnixX is at the forefront of the blockchain gaming and NFT revolution. We're not just another coin; we're the key to unlocking a new era in gaming and digital collectibles. Our mission is to empower gamers, creators, and collectors with cutting-edge technology, transparency, and community-driven decision-making.</p>
                                <a href="https://docs.vitnixx.com/" className="vx_border-button mt-3"><span>Whitepaper</span></a>
                            </div>
                        </div>
                        <div className="col-lg-7 order-lg-0 text-center">
                            {/* <div data-aos="fade-up" data-aos-duration="3000" className="vx_services-images">
                                <img className='mb-4' src={banneranim2} width="500" height="500" alt="Services img" />
                            </div> */}
                            <div className="services__wrapper">
                            <img className='mb-4' src={gamesimg} width="500" height="500" alt="Services img" />

                                {/* <div className="vx_ecosystem-item"> */}
                                    {/* <div className="vx_ecosystem-content">
                                        <img className='mb-4' src={aboutblog1} width="100" height="100" alt="Services img" />
                                        <h3 data-aos="fade-up" data-aos-duration="3000" className="title">Connect wallet</h3>
                                        <p data-aos="fade-up" data-aos-duration="3000">Use Trust Wallet, Metamask or any wallet to connect to the mobile app.</p>
                                    </div> */}
                                {/* </div> */}
                                {/* <div className="vx_ecosystem-item">
                                    <div className="vx_ecosystem-content">
                                        <img className='mb-4' src={aboutblog2} width="100" height="100" alt="Services img" />
                                        <h3 className="title">Buy your NFT</h3>
                                        <p>Upload your NFTs and set a title, description and price.</p>
                                    </div>
                                </div> */}
                                {/* <div className="vx_ecosystem-item">
                                    <div className="vx_ecosystem-content">
                                        <img className='mb-4' src={aboutblog3} width="100" height="100" alt="Services img" />
                                        <h3 className="title">Create artwork</h3>
                                        <p>Upload your NFTs and set a title, description and price.</p>
                                    </div> */}
                                {/* </div>
                                <div className="vx_ecosystem-item">
                                    <div className="vx_ecosystem-content">
                                        <img className='mb-4' src={aboutblog4} width="100" height="100" alt="Services img" />
                                        <h3 className="title">List them for sale</h3>
                                        <p>Earn ETH and BIT for all your NFTs that you sell on our marketplace.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Aboutus Section End */}
        </>
    )
}

export default Aboutus