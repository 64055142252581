import React from "react";
import tokenomics from "../../Assets/images/tokenomics-img.svg";
import { useRefs } from "../../Pages/RefsContext";

const Tokenomics = () => {
  const { tokenomicsRef } = useRefs();
  return (
    <>
      {/* Tokenomics Section Start */}
      <section
        ref={tokenomicsRef}
        className="vx_tokenomicsmain section-pt-50 section-pb-50"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-10">
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className="vx_section-title text-center mb-60"
              >
                <span className="vx_sub-title vx_animate-text">
                  <span>Tokenomics at a Glance</span>
                </span>
                <h3 className="title">Tokenomics</h3>
              </div>
            </div>
          </div>
          <div className="row align-items-center" style={{ margin: "0 auto" }}>
            <div className="col-lg-6">
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className="vx_tokenomicsmain_images mt-4"
              >
                <img
                  src={tokenomics}
                  width="705"
                  height="705"
                  alt="tokenomics img"
                />
              </div>
            </div>
            <div className="col-lg-6 tokenomicstable ">
              <h2>Coin Details</h2>
              <ul>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                      <strong>Chain Network :</strong>
                    </td>
                    {/* <td>Polygon</td> */}
                  </tr>
                </li>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                    <td>Polygon</td>
                      {/* <strong>Token Name:</strong> */}
                    </td>
                    {/* <td>VitnixX</td> */}
                  </tr>
                </li>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                 <strong>Coin Name :</strong> 
                    </td>
                    {/* <td >VTC</td> */}
                  </tr>
                </li>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                        <td >VitnixX</td> 
                      {/* <strong>Smart Contract:</strong> */}
                    </td>
                    {/* <a href="https://polygonscan.com/token/0x7d24903b4c4c81456cf9bf66b73ce95dc6914950" target="_blank" rel="noreferrer">0x7d24..14950</a> */}
                  </tr>
                </li>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                 <strong>Token Ticker :</strong> 
                    </td>
                    {/* <td >VTC</td> */}
                  </tr>
                </li>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                        <td >VTC</td> 
                      {/* <strong>Smart Contract:</strong> */}
                    </td>
                    {/* <a href="https://polygonscan.com/token/0x7d24903b4c4c81456cf9bf66b73ce95dc6914950" target="_blank" rel="noreferrer">0x7d24..14950</a> */}
                  </tr>
                </li>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                    <strong>Smart Contract :</strong> 

                      {/* <strong>Smart Contract:</strong> */}
                    </td>
                    {/* <a href="https://polygonscan.com/token/0x7d24903b4c4c81456cf9bf66b73ce95dc6914950" target="_blank" rel="noreferrer">0x7d24..14950</a> */}
                  </tr>
                </li>
                <li data-aos="fade-up" data-aos-duration="3000">
                  <tr>
                    <td>
                        
                    </td>
                    <a href="https://polygonscan.com/token/0x7d24903b4c4c81456cf9bf66b73ce95dc6914950" target="_blank" rel="noreferrer">0x7d24..14950</a>
                  </tr>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Tokenomics Section End */}
    </>
  );
};

export default Tokenomics;
