import React from 'react'
import banneranim from '../../Assets/images/v1 (2).gif'
import { useRefs } from '../../Pages/RefsContext'
const Banner = () => {
    const { homeRef } = useRefs();
    return (
        <>
            {/* Banner Section Start */}
            <section ref={homeRef} className="vx_banner vx_banner-bg">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xxl-6 col-xl-5 col-lg-6 order-lg-1">
                            <div className="vx_banner-img text-center">
                                <div className="mx-auto w-fit-content d-inline">
                                    <img src={banneranim} width="520" height="875" alt="Banner images" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-0">
                            <div className="vx_banner-content vx_section-title">
                                {/* <h1 data-aos="fade-up" data-aos-duration="3000" className="title mb-5">
                                    <svg viewBox="0 0 1170 270">
                                        <text x="50%" y="50%" dy=".35em" text-anchor="middle">
                                            VITNIXX
                                        </text>
                                    </svg></h1> */}

                                <div class="vx_section-title text-left mb-60"><span class="vx_sub-title vx_animate-text"><span className=' '>VitnixX</span></span></div>
                                <h2 data-aos="fade-up" data-aos-duration="3000" className=" mb-2"> <span className=' span_title'>VitnixX </span>Coin - Empowering the Future of Gaming and NFTs</h2>
                                <p data-aos="fade-up" data-aos-duration="3000">Join the Revolution in Decentralized Gaming and Digital Collectibles</p>
                                <div data-aos="fade-up" data-aos-duration="3000" className="vx_button d-flex flex-wrap">
                                    <a href="https://swap.vitnixx.com/" className="vx_border-button"><span>Swap</span></a>
                                    <a href="http://nft.vitnixx.com/" className="vx_border-button"><span>Nft Marketplace</span></a>
                                    <a href="https://game.vitnixx.com/" className="vx_border-button" target='_blank'><span>Games</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section End */}
        </>
    )
}

export default Banner