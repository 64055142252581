import React from 'react';
import instagram from '../../Assets/images/instagram3.png'
import twitter from '../../Assets/images/twitter2.png'
import youtube from '../../Assets/images/youtube.png'
import medium from '../../Assets/images/medium.png'
import facebook from '../../Assets/images/facebook.png'
import telegram from  '../../Assets/images/telegram.png'
import Reddit  from '../../Assets/images/reddit.png'
import backtotop from '../../Assets/images/icons/top.webp'

const Footer = () => {
    const scrollToTop = (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            {/* Footer Section Start */}
            <footer className='vx_footer'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="vx_footer-copyright-text">
                                <p>Copyright © 2023 - All Rights Reserved By <span>Vitnixx</span></p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="vx_footer-card text-center text-md-end">
                                <div className="vx_footer-social">
                                    <a href=" https://www.instagram.com/vitnixxcoinofficial/" target="_blank" rel="noreferrer" title="Instagram"><img src={instagram} width="22" height="22" className='instagram' alt="instagram icon" /></a>
                                    <a href="https://www.youtube.com/channel/UCt5pNL0FiKDMsR74NYGdmmQ" target="_blank" rel="noreferrer" title="Youtube"><img src={youtube} width="22" height="22" alt="youtube icon" /></a>
                                    <a href=" https://www.facebook.com/vitnixxcoinofficial/" target="_blank" rel="noreferrer" title="Medium"><img src={facebook} width="22" height="22" alt="medium icon" /></a>
                                    <a href="https://t.me/vitnixxcoin" target="_blank" rel="noreferrer" title="Medium"><img src={telegram} width="22" height="22" alt="medium icon" /></a>
                                    <a href="https://www.reddit.com/user/Vitnixxcoinofficial" target="_blank" rel="noreferrer" title="Medium"><img src={Reddit } width="22" height="22" alt="medium icon" /></a>
                                    <a href="https://medium.com/@vitnixxcoin" target="_blank" className='medium' rel="noreferrer" title="Medium"><img src={medium} width="22" height="22" alt="medium icon" /></a>
                                    <a href=" https://twitter.com/vitnixxofficial" target="_blank" className='twitter' rel="noreferrer" title="Twitter"><img src={twitter} width="22" height="22" alt="twitter icon" /></a>






                                    <a href="#!" onClick={scrollToTop} target="_blank" className='backtotop' rel="noreferrer">
                                        <img src={backtotop} width="30" height="30" alt="backtotop icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section End */}
        </>
    )
}

export default Footer