// RefsContext.jsx

import { createContext, useContext, useRef } from 'react';

export const RefsContext = createContext();

export const useRefs = () => useContext(RefsContext);

export const RefsProvider = ({ children }) => {
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const featuresRef = useRef(null);
    const gamesRef = useRef(null);

    const tokenomicsRef = useRef(null);
    const roadmapRef = useRef(null);
    const faqRef = useRef(null);
    // Add more refs here for each section

    const value = {
        homeRef,
        aboutRef,
        featuresRef,
        gamesRef,
        tokenomicsRef,
        roadmapRef,
        faqRef,
        // ... additional refs
    };

    return (
        <RefsContext.Provider value={value}>
            {children}
        </RefsContext.Provider>
    );
};
