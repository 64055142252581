import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import logo from '../../Assets/images/Logo (1).png'
import instagram from '../../Assets/images/icons/instagram.webp'
import twitter from '../../Assets/images/icons/twitter.webp'
import youtube from '../../Assets/images/icons/youtube.webp'
import medium from '../../Assets/images/icons/medium.webp'
import closeicon from '../../Assets/images/close.svg'
import { useRefs } from '../../Pages/RefsContext';

const Header = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [className, setClassName] = useState('');

    const { homeRef, aboutRef, featuresRef, gamesRef, tokenomicsRef, roadmapRef, faqRef } = useRefs();

    const scrollToRef = (ref) => {
        if (ref && ref.current) {
            const top = ref.current.offsetTop - 80; // Adding 100 pixels to the offsetTop value
            window.scrollTo({
                top: top,
                behavior: 'smooth',
            });
        }
    };


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos === 0) {
                setClassName('');
            } else if (prevScrollPos < currentScrollPos && currentScrollPos > 100) {
                setClassName('vx_sticky-menu');
            } else if (prevScrollPos > currentScrollPos && currentScrollPos < 100) {
                setClassName('');
            } else {
                setClassName('vx_sticky-menu vx_sticky-menu-show');
            }

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]); // handleScroll is defined within the useEffect, so no dependency warning
    // eslint-disable-next-line
    const [menuVisible, setMenuVisible] = useState(false);

    // Function to show the mobile menu
    const showMenu = () => {
        setMenuVisible(true);
        document.body.classList.add('mobile-menu-visible');
    };

    // Function to hide the mobile menu
    const hideMenu = () => {
        setMenuVisible(false);
        document.body.classList.remove('mobile-menu-visible');
    };

    // Loader
    // const [counter, setCounter] = useState(0);
    // const [isPageLoaded, setIsPageLoaded] = useState(false);

    // useEffect(() => {
    //     window.onload = () => {
    //         setIsPageLoaded(true);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (isPageLoaded) {
    //         const tl = anime.timeline();

    //         tl.add({
    //             targets: ['.loaderx', '.loader__layer', '.loader__container'],
    //             duration: 700,
    //             visibility: 'hidden',
    //             translateY: '-100%',
    //             easing: 'easeInExpo',
    //             delay: 300,
    //             complete: function (anim) {
    //                 anime.remove('.loaderx, .loader__layer, .loader__container'); // Remove these elements from the anime.js instance.
    //             }
    //         });
    //     } else {
    //         const elements = document.querySelectorAll('.loaderx, .loader__layer, .loader__container');
    //         elements.forEach(el => {
    //             el.style.visibility = 'visible';
    //             el.style.transform = 'translateY(0)';
    //         });
    //     }
    // }, [isPageLoaded]);

    // Still use setInterval for updating the counter.
    // useEffect(() => {
    //     const loaderTimer = setInterval(() => {
    //         setCounter((prevCounter) => {
    //             if (prevCounter < 100) {
    //                 const newCounter = prevCounter + 1;

    //                 anime({
    //                     targets: '.loader__layer',
    //                     duration: 0,
    //                     width: `${newCounter}%`,
    //                 });

    //                 return newCounter;
    //             } else {
    //                 return prevCounter;
    //             }
    //         });
    //     }, 35);

    //     return () => {
    //         clearInterval(loaderTimer);
    //     };
    // }, []);

    return (
        <>
            {/* <div className="loaderx">
                <div className="loader__container">
                    <h1 className="loader__container__counter" aria-label="Loading Counter">{`${counter}%`}</h1>
                    <h2 className="loader__container__loading" aria-label="Loading">loading...</h2>
                </div>
                <div className="loader__layer" aria-hidden="true"></div>
            </div> */}
            {/* Header Section Start */}
            <header>
                <div id="sticky-header" className={`vx_header-main transparent-header ${className}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler" onClick={showMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <nav className="vx_menu-nav">
                                    <div className="logo">
                                        <a href="/">
                                            <img src={logo} width="177" height="39" alt="Vitnixx Logo" />
                                        </a>
                                    </div>
                                    <div className="vx_menu-navbar-wrap vx_menu-main d-none d-xl-flex">
                                        <ul className="navigation">
                                            <li onClick={() => scrollToRef(homeRef)}><a href="#Home">Home</a></li>
                                            <li onClick={() => scrollToRef(aboutRef)}><a href="#About">About Us</a></li>
                                            <li onClick={() => scrollToRef(featuresRef)}><a href="#Features">Features</a></li>
                                            <li onClick={() => scrollToRef(gamesRef)}><a href="#Games">Games</a></li>

                                            <li onClick={() => scrollToRef(tokenomicsRef)}><a href="#Tokenomics">Tokenomics</a></li>
                                            <li onClick={() => scrollToRef(roadmapRef)}><a href="#Roadmap">Roadmap</a></li>
                                            <li onClick={() => scrollToRef(faqRef)}><a href="#Faq">Faq's</a></li>
                                        </ul>
                                    </div>
                                    {/* <div className="vx_footer-card text-center text-md-end">
                                        <div className="vx_footer-social">
                                            <a href="#!" target="_blank" rel="noreferrer" title="Instagram"><img src={instagram} width="22" height="22" className='instagram' alt="instagram icon" /></a>
                                            <a href="#!" target="_blank" rel="noreferrer" title="Twitter"><img src={twitter} width="22" height="22" alt="twitter icon" /></a>
                                            <a href="#!" target="_blank" rel="noreferrer" title="Youtube"><img src={youtube} width="22" height="22" alt="youtube icon" /></a>
                                            <a href="#!" target="_blank" rel="noreferrer" title="Medium"><img src={medium} width="22" height="22" alt="medium icon" /></a>
                                        </div>
                                    </div> */}
                                    {/* <div className="vx_menu-action d-none d-md-block">
                                        <ul className="list-wrap">
                                            <li className="header-btn">
                                                <a href="#!" className="vx_border-button">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </nav>
                                {/* Mobile Menu */}
                                <div className="vx_mobile-menu">
                                    <nav className="vx_menu-box">
                                        <div className="close-btn" onClick={hideMenu}>
                                            <img src={closeicon} width="20" height="20" alt="Close Icon" />
                                        </div>
                                        <div className="nav-logo">
                                            <a href="/">
                                                <img src={logo} width="177" height="39" alt="Vitnixx Logo" />
                                            </a>
                                        </div>
                                        <div className="vx_mobile-menu-outer">
                                            <ul className="navigation">
                                                <li onClick={() => { scrollToRef(homeRef); hideMenu(); }}><a href="#Home">Home</a></li>
                                                <li onClick={() => { scrollToRef(aboutRef); hideMenu(); }}><a href="#About">About Us</a></li>
                                                <li onClick={() => { scrollToRef(featuresRef); hideMenu(); }}><a href="#Features">Features</a></li>
                                            <li onClick={() => scrollToRef(gamesRef)}><a href="#Games">Games</a></li>

                                                <li onClick={() => { scrollToRef(tokenomicsRef); hideMenu(); }}><a href="#Tokenomics">Tokenomics</a></li>
                                                <li onClick={() => { scrollToRef(roadmapRef); hideMenu(); }}><a href="#Roadmap">Roadmap</a></li>
                                                <li onClick={() => { scrollToRef(faqRef); hideMenu(); }}><a href="#Faq">Faq's</a></li>
                                            </ul>
                                        </div>
                                        {/* <div className="vx_social-links">
                                            <ul className="list-wrap">
                                                <li><a href="#!" target="_blank" rel="noreferrer"><img src={instagram} width="22" height="22" className='instagram' alt="instagram icon" /></a></li>
                                                <li><a href="#!" target="_blank" rel="noreferrer"><img src={twitter} width="22" height="22" alt="twitter icon" /></a></li>
                                                <li><a href="#!" target="_blank" rel="noreferrer"><img src={youtube} width="22" height="22" alt="youtube icon" /></a></li>
                                                <li><a href="#!" target="_blank" rel="noreferrer"><img src={medium} width="22" height="22" alt="medium icon" /></a></li>
                                            </ul>
                                        </div> */}
                                    </nav>
                                </div>
                                <div className="vx_mobile-menu-bg" onClick={hideMenu}></div>
                                {/* Mobile Menu End */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Header Section End */}
        </>
    )
}

export default Header