import React  from 'react';
// import "react-marquee-slider/styles.css";
import americanroulette from '../../Assets/images/americanroulette.webp'
import dice from '../../Assets/images/dice.webp'
import crash from '../../Assets/images/crash.webp'
import blackjack from '../../Assets/images/blackjack.webp'
import fruitslots from '../../Assets/images/fruitslots.webp'
import  luckywheel from '../../Assets/images/luckywheel.webp'
import dice3d from '../../Assets/images/dice3d.webp'
import baccarat from '../../Assets/images/baccarat.webp'
import headtails from '../../Assets/images/headtails.webp'
import keno from '../../Assets/images/keno.webp'
import plinko from '../../Assets/images/plinko.webp'
import sicbo from  '../../Assets/images/sicbo.webp'
import animalslots from '../../Assets/images/animalslots.webp'
import poker from '../../Assets/images/poker.webp'
import horseracing from '../../Assets/images/horseracing.webp' 
import casino from '../../Assets/images/casino.webp'
import multiplayerblackjack from '../../Assets/images/multiplayerblackjack.webp'
import videopoker from '../../Assets/images/videopoker.webp'
import { useRefs } from '../../Pages/RefsContext'
import crypto from '../../Assets/images/bennar - 1_crypto prediction.png' 




const images = [
  
    { src: americanroulette, alt: "American Roulette", href: "https://game.vitnixx.com/"  },
    { src: blackjack, alt: "blackjack", href: "https://game.vitnixx.com/"  },
    { src: crash, alt: "Crash", href: "https://game.vitnixx.com/" },
    { src: dice, alt: "Dice", href: "https://game.vitnixx.com/"  },
    { src: plinko, alt: "plinko", href: "https://game.vitnixx.com/" },
    { src: luckywheel, alt: "luckywheel", href: "https://game.vitnixx.com/" },
    { src: baccarat, alt: "baccarat" , href: "https://game.vitnixx.com/" },
    { src: headtails, alt: "headtails" , href: "https://game.vitnixx.com/" },
    { src: poker, alt: "poker" , href: "https://game.vitnixx.com/" },
    { src: crypto, alt: "crypto" , href: "https://game.vitnixx.com/" },





   
    { src: fruitslots, alt: "fruitslots", comingSoon: true },
    { src: dice3d, alt: "dice3d" , comingSoon: true },
   
    { src: keno, alt: "keno"  , comingSoon: true },
    { src: sicbo, alt: "sicbo" , comingSoon: true },
    { src: animalslots, alt: "animalslots" , comingSoon: true  },
    { src: horseracing, alt: "horseracing" , comingSoon: true  },
    { src: casino, alt: "casino " , comingSoon: true  },
    { src: multiplayerblackjack, alt: "multiplayerblackjack" , comingSoon: true },
    { src: videopoker, alt: "videopoker" , comingSoon: true },

  ];
  
  
 
  function Animation() {
const { gamesRef } = useRefs();

    return (
        <section ref={gamesRef} className="banneranimation section-pt-50 section-pb-50">
                <div className="container">
            
                <div data-aos="fade-up" data-aos-duration="1500" className="row justify-content-center mb-5">
                        {/* <div className="col-xl-6 col-lg-7 col-md-10"> */}
                            <div className="vx_section-title text-center mb-60">
                                <span className="vx_sub-title vx_animate-text"><span>VitnixX Game</span></span>
                                <h3 className="title">VitnixX games</h3>
                            </div>
                        </div>
                    {/* </div> */}
           
       <div className="marquee">
 
      <div className="marquee-content" onMouseEnter={() => document.querySelector(".marquee-content").style.animationPlayState = "paused"} onMouseLeave={() => document.querySelector(".marquee-content").style.animationPlayState = "running"}>
        {images.map((image, index) => (
          <div key={index} className="hexagon">
            <div className="animationimg">

              <img src={image.src} alt={image.alt}  />
              {image.comingSoon && (
                <div className="lablesection">
                    <div className="label">
                        <span> Coming soon </span>
                    </div>
                </div>
            )}
              </div>

            </div>
        ))}
        {images.map((image, index) => (
          <div key={index} className="hexagon">
            <a href={image.href} target='_blank'>
            <div className="animationimg">
              <img src={image.src} alt={image.alt} />
            </div>
            </a>
          </div>
        ))}
      </div>
    </div>
    </div>
        </section>
      );
    }  

export default Animation;
